<template>
  <VRating
    background-color="#A4A2B7"
    class="mr-2"
    color="#FFB340"
    dense
    empty-icon="mdi-star"
    hover
    readonly
    size="26"
    :value="value"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
};
</script>
